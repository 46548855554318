import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";



import {
  Row,
  // Alert,
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { assests } from "../../Assests/assets";
import { DynamicButton } from "../../Components/DynamicButton";
import GlobalNav from "../../Components/GlobalNavbar";
import { baseDomain, loginAPI } from "../../Constants/apiRoutes";
import { clearLocal, getLocal, isAuthorized, setLocal } from "../../Utils/localStorage";
import { loginSitterAPI, resetPassword } from "./Auth.apis";
import Masthead from "../../Components/Masthead";
import MobileMasthead from "../../Components/MobileMasthead";
import { MyRouteContext } from "../../Utils/myContext";

const Authentication = () => {
  const navigate = useNavigate();
  const { isUserAuthenticated, setIsUserAuthenticated } = useContext(MyRouteContext);
  const [isForgotPasswordAlertModal, setIsForgotPasswordAlertModal] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [resetEmail, setResetEmail] = useState('')
  const [loginCred, setLoginCred] = useState({
    email: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (isAuthorized() && window.localStorage.getItem('userStatus') === 'approved') navigate("/sitter/home");
  }, []);

  useEffect(() => {
    if(errMsg){
      setTimeout(() => {
        setErrMsg("")
      }, 5000);
    }
  },[errMsg])

  const loginSitterAPI = (data) => {
    const isRedirected = sessionStorage.getItem('isRedirected')
    return axios.post(`${baseDomain}${loginAPI}`, data).then((res) => {
      if (res.data.data.first_login === false) {
        setLocal("first_time", "No");
      }
      if (res.data.response_code === 90) {
        toast.error(res.data.developer_message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      if (res.data.response_code === 80) {
        const token = res.data.data.token;
        const userDataFName = res.data.data.first_name;
        const userDataLName = res.data.data.last_name;
        const userDataDOB = res.data.data.date_of_birth;
        const userDataAddress = res.data.data.full_address;
        const userStatus = res.data.data.profile_status;
        const isKycDocsUploaded = res.data.data.is_kyc_docs_uploaded;

        window.localStorage.setItem("userStatus", userStatus);
        window.localStorage.setItem("userDataFName", userDataFName);
        window.localStorage.setItem("userDataLName", userDataLName);
        window.localStorage.setItem("userDataDOB", userDataDOB);
        window.localStorage.setItem("userDataAddress", userDataAddress);
        window.localStorage.setItem("isKycDocsUploaded", isKycDocsUploaded);
        // console.log(res.data.profile_status)
        // res.data.data.profile_status === 'approved' ? navigate('/sitter/home') : navigate('/sitter/kyc');
        if (
          res.data.data.is_kyc_docs_uploaded === false &&
          res.data.data.profile_status === "pending"
        ) {
          toast.warning(
            "Please be patient we are reviewing your profile and will update you via email. ",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
        } else if (res.data.data.is_kyc_docs_uploaded === false) {
          window.localStorage.setItem("token", token);
          setIsUserAuthenticated(true)
          navigate("/bookingpreferences");
        } else if (res.data.data.profile_status === "rejected") {
          toast.error("Please reach out to administrator", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          clearLocal()
        } else if (
          res.data.data.is_kyc_docs_uploaded === true &&
          res.data.data.profile_status === "partially_approved"
        ) {
          toast.warning(
            "Please be patient we are reviewing your documents and will update you via email. ",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
        } else if (
          res.data.data.is_kyc_docs_uploaded === true &&
          res.data.data.profile_status === "approved"
        ) {
          window.localStorage.setItem("token", token);
          setIsUserAuthenticated(true)
          if(isRedirected){
            navigate(isRedirected)
            sessionStorage.removeItem('isRedirected')
          }else navigate("/sitter/home");
        }
      } else {
        console.log("login error")
      }
    });
  };

  const { email, password } = loginCred;

  const handelInput = (e) => {
    setLoginCred({
      ...loginCred,
      [e.target.name]: e.target.value,
    });
  };

  const handelLogin = async (e) => {
    e.preventDefault();
    await loginSitterAPI({
      email,
      password,
    });
  };

  const  handelResetEmail = (e) => {
    setIsDisabledBtn(false)
    setResetEmail(e.target.value)
  }

  const handleSendResetPasswordReq = async() =>{
    const { data } = await resetPassword({
      email: resetEmail
    })
    if(data.response_code == 80){
      setIsForgotPasswordAlertModal(false)
      setResetEmail('')
    }

    if(data.response_code == 90){
      setErrMsg(data.developer_message)
      // setIsForgotPasswordAlertModal(false)
      // setResetEmail('')
    }

  }
  return (
    <Fragment>
      <div>
        <Masthead/>
        <Container fluid className="p-0">
          <Row className="no-gutters w-100 m-0 flex-row-reverse">
            <Col lg={6}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          {/* <div>
                            <img
                              width="160px"
                              src={assests.dogstaysLogo}
                              alt="Dogstays"
                            />
                          </div> */}
                          <h4 className="font-size-18 mt-4">Welcome Back!</h4>
                          <p className="text-muted">Sign in to continue.</p>
                        </div>
                        <div className="p-2 mt-3">
                          <Form
                            className="form-horizontal"
                            onSubmit={handelLogin}
                          >
                            <FormGroup className="auth-form-group-custom mb-4">
                            <i className="ri-user-2-line auti-custom-input-icon"><MdEmail/></i>
                              <Label htmlFor="email">Email</Label>
                              <Input
                                name="email"
                                value={email}
                                onChange={handelInput}
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                              />
                            </FormGroup>

                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"><RiLockPasswordFill/></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <Input
                                name="password"
                                value={password}
                                onChange={handelInput}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                              />
                            </FormGroup>
                            <div className="forgot-pass" >
                              <a
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                  setIsForgotPasswordAlertModal(true);
                                }}
                              >
                                Forgot Password?
                              </a>
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                className="w-md waves-effect waves-light login-btn w-100"
                                type="submit"
                              >
                                LogIn
                              </Button>
                            </div>
                          </Form>
                        </div>
                        <div className="mt-5 text-center">
                          <p>© {new Date().getFullYear()} Dogstays.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6} className="p-0">
              <div className="authentication-bg">
                {/* <div className="bg-overlay"></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isForgotPasswordAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isForgotPasswordAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <FormGroup>
                <Label htmlFor="email">
                 Email
                </Label>
                <Input
                  name="email"
                  placeholder="Enter Email"
                  value={resetEmail}
                  onChange={handelResetEmail}
                  type="email"
                  errorMessage="email is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="email"
                />
              </FormGroup>
              <p>Note: Reset password link will be sent to this email only if it is associated with a registered user.</p>
              {errMsg && <p style={{color: 'red'}}>{errMsg}</p>}
              <div className="col-12 text-center d-flex justify-content-center gap-2">
              <DynamicButton
                  createBtnText="Reset Password"
                  isDisabledBtn={isDisabledBtn}
                  clickFunction={handleSendResetPasswordReq}
                />
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsForgotPasswordAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Fragment>
  );
};

export default Authentication;
