import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import { otherRoutes, UsersRoutes } from './Views/Pages/Constants/routes'
import DecisionComponent from './Components/DecisionComponent'
import { useEffect, useState } from "react";
import { MyRouteContext } from "./Utils/myContext";


const App = () => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false)

  useEffect(() => {
    const istokenPresent = window.localStorage.getItem('token')
    istokenPresent && setIsUserAuthenticated(true)
    const pathName = window.location.pathname
    if(pathName.includes('/booking/information/')) {
      sessionStorage.setItem('isRedirected', pathName)
    }
  },[])

  useEffect(() => {
  },[isUserAuthenticated])

  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: false,
          includedLanguages: "en,fr,de"
        },
        "google_translate_element"
      );
    };
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <>
    <div id="google_translate_element"></div>
    <MyRouteContext.Provider value={{ isUserAuthenticated, setIsUserAuthenticated }}>
    <Router>
      <Routes>
        {isUserAuthenticated && UsersRoutes.map(({ Component, path }) => (
          <Route element={<Component />} path={path} key={path} />
        ))}
        {!isUserAuthenticated && otherRoutes.map(({ Component, path }) => (
          <Route element={<Component />} path={path} key={path}/>
        ))}
         {<Route element={<DecisionComponent />} path="*" />}
      </Routes>
    </Router>
    <ToastContainer autoClose={4000}/>
    </MyRouteContext.Provider>
    </>
  )
}

export default App
